// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/posts/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-posts-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-templates-blog-post-listing-tsx": () => import("./../../../src/templates/blog-post-listing.tsx" /* webpackChunkName: "component---src-templates-blog-post-listing-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */)
}

